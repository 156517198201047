<template>
    <div class="mx-auto hidden md:flex gap-2 rounded-full p-[6px]" style="background-color: #EEF1F6;">
        <template v-for="entry in options">

            <NuxtLink :to="entry.url" target="blank" v-if="entry.url" class="px-6 py-3 rounded-3xl transition-all flex align-center gap-2 border-2 hover:border-my-green"
                :class="entry.value === model ? 'bg-white text-black border-my-green' : 'bg-gray-bg-50 cursor-pointer text-gray-text border-transparent'"
                >
                <Icon size="20" :name="entry.icon" class="block" />
                <span class="text-[16px]/[20px]">
                    {{ entry.label }}
                </span>
            </NuxtLink>
            <div v-else class="px-6 py-3 rounded-3xl transition-all flex align-center gap-2 border-2 hover:border-my-green"
                :class="entry.value === model ? 'bg-white text-black border-my-green' : 'bg-gray-bg-50 cursor-pointer text-gray-text border-transparent'"
                @click="model = entry.value" >
                <Icon size="20" :name="entry.icon" class="block" />
                <span class="text-[16px]/[20px]">
                    {{ entry.label }}
                </span>
            </div>

        </template>
    </div>
    <Carousel v-model="model" class="md:hidden w-full">
        <Slide
            class="px-6 py-3 rounded-3xl transition-all flex align-center gap-2 border-2 hover:border-my-green bg-white text-black border-my-green"
            v-for="entry in options">
            <Icon size="20" :name="entry.icon" class="block" />
            <span class="text-[16px]/[20px]">
                {{ entry.label }}
            </span>
        </Slide>

        <template #addons>
            <Navigation />
        </template>
    </Carousel>
</template>

<script setup>
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/carousel.css'
const { t } = useI18n()
const props = defineProps({
    options: {
        required: true,
        type: Array
    }
})

const model = defineModel()
watch(model, (v)=>{
    if (props.options[v].url)
    window.open(props.options[v].url, '_blank').focus();
})
</script>