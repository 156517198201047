<template>
    <UContainer>
        <h2 class="text-[24px]/[30px] md:text-[32px]/[40px] xl:text-[42px]/[53px] text-center mb-[40px]">
            {{ t('additionalServices') }}
        </h2>
        <!-- <div
            class="text-gray-text text-center text-[14px]/[17px] md:text-[16px]/[19px] xl:text-[20px]/[24px] mb-[40px]">
            {{ t('flyToAdventures') }}</div> -->

        <div class="md:grid grid-cols-4 xl-gap-[60px] mb-6 md:mb-12">

            <div class="mb-[40px]" v-for="serv in services.slice(0, 4)">
                <div class="flex w-full justify-center mb-6">
                    <div class="p-6 mx-auto rounded-full overflow-hidden bg-white">
                        <img :src="serv.icon" alt="" class="w-[48px] h-[48px]">
                    </div>
                </div>

                <div style="min-height: 60px;"
                    class="text-center text-[20px]/[30px] md:text-[16px]/[24px] xl:text-[20px]/[30px] font-semibold mb-2">
                    {{ serv.title }}
                </div>
                <div class="text-[14px]/[21px] md:text-[16px]/[24px] font-normal text-center"
                    style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
                    {{ serv.description }}
                </div>
                <div class="text-center mt-8">
                    <a :href="serv.url"
                        class="text-black hover:text-white py-3 px-8 bg-transparent hover:bg-my-green rounded-full border-2 border-my-green inter font-semibold"
                        target="blank" v-if="serv.form_type === 'Order'">
                        {{ t('order') }}
                    </a>
                    <span
                        class="text-black hover:text-white py-3 px-8 bg-transparent hover:bg-my-green rounded-full border-2 border-my-green inter font-semibold"
                        v-else @click="openRequestModal(serv)">
                        {{ t('leaveApplication') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <NuxtLink :to="localePath('/additional')"
                class="py-4 px-6 bg-my-green mx-auto block rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green">
                {{ t('seeAll') }}
            </NuxtLink>
        </div>
        <ApplicationServiceRequest :selected ref="modal" />

    </UContainer>
</template>

<script setup>
const { t } = useI18n()
const localePath = useLocalePath()
const props = defineProps({
    services: {
        type: Array
    }
})
const selected = ref()
const modal = ref()
function openRequestModal(e) {
    selected.value = e
    modal.value.openModal()
}
</script>
