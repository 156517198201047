<template>
    <UContainer class="hero mb-[50px] md:mx-8 xl:mx-auto">
        <div class="-mx-4 sm:-mx-6 lg:-mx-8 relative">
            <Carousel mouseDrag pauseAutoplayOnHover wrap-around class="z-important hidden md:block" ref="car"
                :autoplay="5000" :transition="500" snapAlign="start">
                <Slide v-for="item in items" :key="item.image"
                    class="h-[512px] rounded-0 md:rounded-32 pt-[100px] px-10 w-full "
                    :style="`background: url('${item.image}') no-repeat center center/cover;`">
                    <div>
                        <h1 class="stapel text-center text-[32px]/[42px] md:text-[42px]/[54px] xl:text-[62px]/[74px]">
                            {{ item.title }}
                        </h1>
                        <p
                            class="text-center inter text-[14px]/[21px] md:text-[16px]/[20px] xl:text-[20px]/[24px] mt-[16px] md:mt-[24px]">
                            {{ item.subtitle }}
                        </p>
                    </div>
                </Slide>
            </Carousel>
            <Carousel mouseDrag pauseAutoplayOnHover wrap-around class="z-important md:hidden" ref="car2"
                :autoplay="5000" :transition="500">
                <Slide v-for="item in items" :key="item.image"
                    class="h-[512px] rounded-0 md:rounded-32 pt-[100px] px-10 w-full"
                    :style="`background: url('${item.mobile_image}') no-repeat center center/cover;`">
                    <div>
                        <h1 class="stapel text-center text-[32px]/[42px] md:text-[42px]/[54px] xl:text-[62px]/[74px]">
                            {{ item.title }}
                        </h1>
                        <p
                            class="text-center inter text-[14px]/[21px] md:text-[16px]/[20px] xl:text-[20px]/[24px] mt-[16px] md:mt-[24px]">
                            {{ item.subtitle }}
                        </p>
                    </div>
                </Slide>
            </Carousel>
            <div
                class="absolute top-[50%] -translate-y-1/2 left-0 right-0 flex justify-between p-2 md:p-10 hidden md:flex">
                <button class="border-2 rounded-full border-transparent hover:border-light-green flex p-1"
                    @click="() => slideCarousel(-1)">
                    <Icon name="ri:arrow-left-line" size="24" />
                </button>
                <button class="border-2 rounded-full border-transparent hover:border-light-green flex p-1"
                    @click="() => slideCarousel(1)">
                    <Icon name="ri:arrow-right-line" size="24" />
                </button>
            </div>
            <div class="absolute top-[50%] -translate-y-1/2 left-0 right-0 flex justify-between p-2 md:p-10 md:hidden">
                <button class="border-2 rounded-full border-transparent hover:border-light-green flex p-1"
                    @click="() => slideCarousel2(-1)">
                    <Icon name="ri:arrow-left-line" size="24" />
                </button>
                <button class="border-2 rounded-full border-transparent hover:border-light-green flex p-1"
                    @click="() => slideCarousel2(1)">
                    <Icon name="ri:arrow-right-line" size="24" />
                </button>
            </div>
        </div>
        <div class="p-6 rounded-3xl border-blue border bg-white -mt-[100px] md:-mt-10">
            <div class="flex align-center justify-center mb-8">
                <BaseTabPicker :options="tabs" v-model="currentTab" />
            </div>

            <ApplicationSearchForm v-if="currentTab === 0" />
            <form v-if="currentTab === 1" class="lg:flex gap-5 items-end" @submit.prevent="searchTicketInfo">
                <div class="lg:flex gap-3 items-end grow">
                    <div class="mb-5 lg:mb-0 grow">
                        <label for="" class="mb-2 text-gray-text text-[14px]/[21px] xl:text-[16px]/[19px] block">
                            {{ t('ticketInfo2') }}
                        </label>
                        <input type="text" placeholder="" maxlength="13"
                            class="ttu invalid:text-red-500 p-3 lg:p-4 xl:p-5 rounded-lg outline-none border-gray-text border-[1px] block w-full"
                            v-model="ticketNumber" required pattern="[a-zA-Z0-9\s\.\-_]+" />
                    </div>
                    <div class="mb-5 lg:mb-0 grow">
                        <label for="" class="mb-2 text-gray-text text-[14px]/[21px] xl:text-[16px]/[19px] block">
                            {{ t('surnameLatin') }}
                        </label>
                        <input type="text" placeholder=""
                            class="ttu  p-3 lg:p-4 xl:p-5 rounded-lg outline-none border-gray-text border-[1px] block w-full invalid:text-red-500"
                            v-model="lastName" required pattern="[a-zA-Z\s'\-]+" />
                    </div>
                </div>
                <div>
                    <button
                        class="py-6 px-6 bg-my-green mx-auto block rounded-full text-white text-[16px]/[19px] lg:text-[14px]/[16px] xl:text-[16px]/[21px] inter transition-shadow button-green flex items-center justify-center gap-2">
                        <Icon name="hugeicons:search-01" size="16" />
                        {{ t('ticketSearch') }}
                    </button>
                </div>
            </form>
            <div v-if="currentTab === 2">
                <iframe width="100%" id="iway-frame" name="iway-frame"
                    :src="`https://iway.ru/steporder/framens?userID=118363&lang=${locale === 'ru' ? 'ru' : 'en'}&currency=USD&pos=iframe`"
                    onload="if (typeof FrameResize != 'undefined') FrameResize.registerFrame(this)" frameborder="0">
                </iframe>
            </div>
        </div>
    </UContainer>
</template>

<script setup>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/carousel.css'

const { t, locale } = useI18n()
const tabs = computed(() => ([
    {
        icon: 'hugeicons:ticket-01',
        label: t('ticketPurchase'),
        value: 0
    },
    {
        icon: 'hugeicons:invoice-01',
        label: t('bookingControl'),
        value: 1

    },
    {
        icon: 'hugeicons:car-01',
        label: t('transfer'),
        value: 2

    },
    {
        icon: 'hugeicons:hotel-02',
        label: t('hotels'),
        value: 3,
        url: 'https://qanotsharq.zenhotels.com'
    },
]))
const rc = useRuntimeConfig()
const headers = computed(() => ({
    lang: locale.value
}))
const res = await $fetch(rc.public.api + `/api/banner`, {
    headers: headers.value
})
const items = ref(
    res.data.banners
)

const currentTab = ref(0)

const ticketNumber = ref('')
const lastName = ref('')
const car = ref()
function slideCarousel(dir) {
    if (dir > 0) {
        car.value.next()
    } else {
        car.value.prev()
    }
}
const car2 = ref()
function slideCarousel2(dir) {
    if (dir > 0) {
        car2.value.next()
    } else {
        car2.value.prev()
    }
}

function searchTicketInfo() {
    window.open(`https://booking.qanotsharq.com/websky_grs/#/search-order/${ticketNumber.value}/${lastName.value}`)
}
</script>

<style lang="scss">
#iway-frame {
    & .iw-frame-usp {
        display: none !important;
    }
}

.z-important {
    z-index: -1 !important;
}
</style>
