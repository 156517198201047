<template>
    <div class="my-container">
        <h2 class="text-[24px]/[30px] md:text-[32px]/[40px] xl:text-[42px]/[53px] text-center mb-2 md:mb-3">
            {{ t('flyWithUs') }} </h2>
        <div
            class="text-gray-text text-center text-[14px]/[17px] md:text-[16px]/[19px] xl:text-[20px]/[24px] mb-[40px]">
            {{ t('flyToAdventures') }}</div>
    </div>
    <UContainer :ui="{ padding: 'pr-0 md:px-8' }" class="mb-[40px]">
        <UCarousel v-slot="{ item }" :items="flights" :ui="{
            item: 'basis-3/4 md:basis-1/3 xl:basis-1/4 ',
            container: 'flex mb-10',
            indicators: {
                wrapper: 'relative bottom-0 mt-4'
            }
        }">
            <div class="pr-8">
                <FlightCard :flight="item" />
            </div>
        </UCarousel>
    </UContainer>

    <div class="flex justify-center">
        <NuxtLink :to="localePath('flights')"
            class="py-4 px-6 bg-my-green mx-auto rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green">
            {{ t('seeAll') }}
        </NuxtLink>
    </div>
</template>

<script setup>
const { t } = useI18n()
const localePath = useLocalePath()
const props = defineProps({
    flights: {
        type: Array
    }
})
</script>