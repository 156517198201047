<template>
    <div
        class="lg:grid lg:grid-cols-3 md:max-w-[1170px] mx-4 lg:mx-8  lg:mx-auto lg:bg-blue-dark-950 lg:p-6 lg:rounded-3xl md:align-stretch">
        <form
            class=" flex flex-col py-6 px-4 lg:py-0 bg-blue-dark-950 rounded-3xl mb-2 lg:mb-0 lg:border-r-2 lg:border-[#334B7D] lg:rounded-none">
            <div>
                <div class="text-white mb-6 inter text-[24px]/[30px] font-semibold	">
                    {{ t('onlineRegister') }}
                </div>
                <div>

                </div>
                <label for="ticket" class="mb-6 block">
                    <div class="text-white mb-2 text-[14px]/[17px] inter">{{ t('bookingCode') }}</div>
                    <input id="ticket" type="text"
                        class="ttu w-full p-4 rounded-xl placeholder-gray-text outline-0 inter invalid:text-red-500"
                        :placeholder="t('symbols6To3')" maxlength="13" pattern="[a-zA-Z0-9\s\.\-_]+">
                </label>
                <label for="lastname" class="block mb-6">
                    <div class="text-white mb-2 text-[14px]/[17px] inter">{{ t('secondNameLatin') }}</div>
                    <input type="text"
                        class="ttu w-full p-4 rounded-xl placeholder-gray-text outline-0 inter invalid:text-red-500"
                        placeholder="" pattern="[a-zA-Z0-9\s\.\-_]+">
                </label>
            </div>
            <div class="grow"></div>

            <button
                class=" w-full text-white hover:text-white py-3 px-8 bg-transparent hover:bg-my-green rounded-xl border-2 border-my-green inter font-semibold">
                {{ t('register') }}
            </button>
        </form>
        <div class="py-6 px-4 lg:py-0 bg-blue-dark-950 rounded-3xl mb-2 flex flex-col lg:mb-0">
            <form class="grow flex flex-col" @submit.prevent="() => tab === 1 ? getNextFlights() : getFlightInfo()">
                <div class="flex justify-between text-white mb-[18px]">
                    <div class="py-2 px-5 border-b-2  text-[16px]/[19px] cursor-pointer"
                        :class="{ 'border-my-green': tab === 1, 'border-transparent': tab === 2 }" @click="tab = 1">
                        {{ t('onlineTable') }}</div>
                    <div class="py-2 px-5 text-[16px]/[19px] border-b-2 cursor-pointer"
                        :class="{ 'border-my-green': tab === 2, 'border-transparent': tab === 1 }" @click="tab = 2">
                        {{ t('flightStatus') }}</div>
                </div>
                <div v-if="tab === 1" class="grow flex flex-col">
                    <label for="ticket" class="mb-6 block">
                        <div class="text-white mb-2 text-[14px]/[17px] inter">{{ t('enterCity') }}</div>
                        <UInputMenu :options="allCities" :placeholder="t('departureArrivalCity')"
                            :option-attribute="'name' + currentLang"
                            :search-attributes="['nameRu', 'nameEn', 'nameUz', 'codeRu', 'codeEn', 'codeUz']"
                            :ui="{ padding: { sm: 'p-4', }, rounded: 'rounded-xl', size: { sm: 'text-[16px]/[24px]' } }"
                            v-model="selectedRoute">
                            <template #option="{ option }">
                                <div>
                                    {{ option['name' + currentLang] }}, {{ option.codeEn }}
                                </div>
                            </template>
                        </UInputMenu>
                    </label>
                    <div class="grow grid grid-cols-2 gap-2 text-white text-[12px] relative" v-if="nextFlights">
                        <Icon name="hugeicons:plus-sign" size=24
                            class="text-white  absolute rotate-45 cursor-pointer right-0 w-[24px] h-[24px]"
                            @click="nextFlights = null" />
                        <div v-if="nextFlights.departure">
                            <div class="flex gap-2 items-center">
                                <Icon name="ri:plane-line" size="16" class="rotate-90" />
                                <span>{{ t('departure') }}</span>
                            </div>
                            <div>
                                {{ nextFlights.departure.DEPARTURE_DATE_LOCAL.split(' ').reverse().join(', ') }}
                            </div>
                            <div>
                                {{ nextFlights.departure.FLIGHT_CODE }}
                            </div>
                            <div>
                                {{ nextFlights.departure.DESTINATION_NAME_TR }}
                            </div>
                            <div class="flex gap-2 items-center">
                                <Icon name="mdi-light:clock" size="16" />
                                <span>
                                    {{ t('status_' + nextFlights.departure.STATUS) }}
                                </span>
                            </div>
                        </div>
                        <div v-if="nextFlights.arrival">
                            <div class="flex gap-2 items-center">
                                <Icon name="ri:plane-line" size="16" class="-rotate-90" />
                                <span>{{ t('arrival') }}</span>
                            </div>
                            <div>
                                {{ nextFlights.arrival.ARRIVAL_DATE_LOCAL.split(' ').reverse().join(', ') }}
                            </div>
                            <div>
                                {{ nextFlights.arrival.FLIGHT_CODE }}
                            </div>
                            <div>
                                {{ nextFlights.arrival.ORIGIN_NAME_TR }}
                            </div>
                            <div class="flex gap-2 items-center">
                                <Icon name="mdi-light:clock" size="16" />
                                <span>
                                    {{ t('status_' + nextFlights.arrival.STATUS) }}
                                </span>
                            </div>
                        </div>
                        <div class="text-center col-span-2 text-[20px] pt-4"
                            v-if="nextFlights.arrival === null && nextFlights.departure === null">
                            {{ t('noFlightsFound') }}
                        </div>
                    </div>
                    <template v-else>
                        <div class="grow"></div>
                        <button type="submit"
                            class="w-full bg-[#334B7D] mb-5 p-4 rounded-xl text-[16px]/[19px] text-white inter font-medium">
                            {{ t('nextFlights') }}
                        </button>
                    </template>
                </div>
                <div v-else class="grow flex flex-col">
                    <div class="flex w-full gap-1 mb-6">
                        <label class="basis-1/2 relative">
                            <div class="text-white mb-2 text-[14px]/[17px] inter">{{ t('flightNumber') }}</div>
                            <input class="w-full p-4 pl-10 rounded-xl placeholder-gray-text outline-0 inter"
                                placeholder="" type="text" v-model="flightNumber">
                            <span class="flight-series">HH</span>
                        </label>
                        <div class="basis-1/2">
                            <div class="text-white mb-2 text-[14px]/[17px] inter">{{ t('departureDate') }}</div>
                            <ApplicationServiceRequestDatePicker secondary v-model="flightDate" />
                        </div>
                    </div>
                    <div v-if="flightInfo" class="relative text-white text-[12px]">
                        <Icon name="hugeicons:plus-sign" size=24
                            class="text-white  absolute rotate-45 cursor-pointer right-0 w-[24px] h-[24px]"
                            @click="flightInfo = null" />
                        <div v-if="flightInfo.flight">
                            <div>
                                {{ flightInfo.flight.FLIGHT_CODE }}
                            </div>
                            <div>
                                {{ flightInfo.flight.ARRIVAL_DATE_LOCAL.split(' ').reverse().join(', ') }}
                            </div>
                            <div>
                                {{ flightInfo.flight.ORIGIN_NAME_TR }} - {{ flightInfo.flight.DESTINATION_NAME_TR }}
                            </div>
                            <div class="flex gap-2 items-center">
                                <Icon name="mdi-light:clock" size="16" />
                                <span>
                                    {{ t('status_' + flightInfo.flight.STATUS) }}
                                </span>
                            </div>
                        </div>
                        <div class="text-center col-span-2 text-[20px] pt-4" v-else>
                            {{ t('noFlight') }}
                        </div>
                    </div>
                    <template v-else>
                        <div class="grow"></div>
                        <button type="submit"
                            class="w-full bg-[#334B7D] mb-5 p-4 rounded-xl text-[16px]/[19px] text-white inter font-medium	">
                            {{ t('flightStatus') }}
                        </button>
                    </template>
                </div>
            </form>
            <div>

                <NuxtLink :to="localePath('/online-table') + '?' + query"
                    class="text-[16px]/[19px] w-full text-white block hover:text-white py-3 lg:py-4 px-8 bg-transparent hover:bg-my-green rounded-xl border-2 border-my-green inter font-semibold text-center">
                    {{ t('goToOnlineTable') }} </NuxtLink>
            </div>

        </div>
        <div class="py-6 px-4 lg:py-0 bg-blue-dark-950 rounded-3xl flex flex-col">
            <div class="rounded-xl bg-[#334B7D] aspect-[2/1] lg:aspect-auto mb-6 grow" style="background-color: #334B7D; 
            background-image: url('/coming-soon.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom center;">


                <!-- <img src="/coming-soon.png" alt="coming-soon" class="h-full mx-auto "> -->
            </div>
            <button disabled
                class="opacity-30 w-full bg-[#334B7D] mb-5 p-4 rounded-xl text-[16px]/[19px] text-white inter font-medium	">
                {{ t('login') }}
            </button>

            <button disabled
                class="opacity-30 text-[16px]/[19px] w-full text-white  py-3 lg:py-4 px-8 bg-transparent  rounded-xl border-2 border-my-green inter font-semibold">
                {{ t('register') }}
            </button>
        </div>
    </div>
</template>
<script setup>
const { locale, t } = useI18n()
const { allCities, initCities } = useCitiesStore()
const localePath = useLocalePath()
const rc = useRuntimeConfig()
await initCities()
const headers = computed(() => ({
    lang: locale.value
}))
const currentLang = computed(() => {
    const langs = {
        en: 'En',
        ru: 'Ru',
        uz: 'Uz',
    }
    return langs[locale.value]
})
const selectedRoute = ref()
const query = computed(() => {
    const res = new URLSearchParams();
    if (!selectedRoute.value) return res;
    Object.entries(selectedRoute.value).forEach(([key, value]) => res.append(key, value))
    return res
})

const tab = ref(1)

const nextFlights = ref()
async function getNextFlights() {
    const path = '/api/online-table/next-flights?CITY=' + selectedRoute.value.nameEn;
    try {
        const res = await $fetch(rc.public.api + path, {
            headers: headers.value
        })
        nextFlights.value = res.data
    } catch (error) {
        nextFlights.value = {}
    }
}
const flightNumber = ref()
watch(() => flightNumber.value, (v) => {
    flightNumber.value = v.replaceAll(/[^0-9]/g, '')
})
const flightDate = ref()
const flightInfo = ref()
async function getFlightInfo() {
    const path = `/api/online-table/flight-info?FLIGHT_NO=${flightNumber.value}&DEPARTURE_DATE=${flightDate.value.toLocaleDateString('en-CA')}`
    try {
        const res = await $fetch(rc.public.api + path, {
            headers: headers.value
        })
        flightInfo.value = res.data
    } catch (error) {

    }

}
</script>
<style lang="scss">
.flight-series {
    position: absolute;
    bottom: 16px;
    left: 16px;
}

.ttu {
    text-transform: uppercase;

    &::placeholder {
        text-transform: none;
    }
}
</style>