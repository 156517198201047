<template>
    <div class="py-[100px] bg-blue-dark-950">
        <div class="my-container flex text-white items-center justify-between">
            <div>
                <h2 class="text-[24px]/[30px] md:text-[32px]/[40px] xl:text-[42px]/[53px]  mb-2 md:mb-3">
                    {{ t('popularFLights') }}
                </h2>
                <div class="text-[14px]/[17px] md:text-[16px]/[19px] xl:text-[20px]/[24px] mb-[40px]">
                    {{ t('goToAdventures') }}
                </div>
            </div>
            <div class="gap-2 text-light-green hidden md:flex">
                <button class="border-2 rounded-full border-transparent hover:border-light-green flex p-1"
                    @click="() => car.prev()">
                    <Icon name="ri:arrow-left-line" size="24" />
                </button>
                <button class="border-2 rounded-full border-transparent hover:border-light-green flex p-1"
                    @click="() => car.next()">
                    <Icon name="ri:arrow-right-line" size="24" />
                </button>
            </div>

        </div>
        <div class="test">
            <Carousel v-bind="config" :autoplay="3000" :transition="300" snapAlign="start" ref="car">
                <Slide v-for="item in popular" :key="item.image" class="rounded-2xl bg-white w-full"
                    style="display: block;">
                    <div class="p-6 pb-8 border-0 border-b-2 border-dashed border-[#E6E8EC]">
                        <!-- TOP line -->
                        <div class="flex w-full gap-2 items-center text-gray-text mb-6">
                            <span class="text-[14px]/[22px] font-medium	">
                                {{ t('from') }}
                            </span>
                            <Icon name="clarity:add-line" size="14" />
                            <div class="grow border-b-2 border-[#E6E8EC]"></div>
                            <Icon name="ri:plane-line" size="24" class="rotate-90 text-blue" />
                            <div class="grow border-b-2 border-[#E6E8EC]"></div>
                            <Icon name="clarity:add-line" size="14" />
                            <span class="text-[14px]/[22px] font-medium	">
                                {{ t('to') }}
                            </span>
                        </div>
                        <!-- City codes -->
                        <div class="flex justify-between items-center mb-2 gap-1">
                            <span class="font-normal text-[34px]/[41px]">{{ item.from_where_code }}</span>
                            <span class="text-gray-text font[12px]/[22px] text-center" v-if="item.duration">
                                {{ durationText(item.duration) }}
                            </span>
                            <span class="font-normal text-[34px]/[41px]">{{ item.to_where_code }}</span>
                        </div>
                        <!-- CITIES -->
                        <div class="flex justify-between text-[14px]/[22px] text-gray-text mb-5">
                            <span>{{ item.from_where }}</span>
                            <span>{{ item.to_where }}</span>
                        </div>
                        <div class="flex justify-between text-[16px]/[22px] mb-1">
                            <span>
                                <template v-if="item.from_date">
                                    {{ item.from_date.slice(11, 16) }}
                                </template>
                            </span>
                            <span>
                                <template v-if="item.to_date">
                                    {{ item.to_date.slice(11, 16) }}
                                </template>
                            </span>
                        </div>
                        <div class="flex justify-between text-[14px]/[22px] text-gray-text">
                            <span>
                                <template v-if="item.from_date">
                                    {{ formatDate(new Date(item.from_date)) }}
                                </template>
                            </span>
                            <span>
                                <template v-if="item.to_date">
                                    {{ formatDate(new Date(item.to_date)) }}
                                </template>
                            </span>
                        </div>
                    </div>
                    <div class="p-2 pt-3 ticket-cutout">
                        <div class="p-4 bg-gray-bg-50 rounded-2xl flex justify-between items-center">
                            <div>
                                <div class="text-[14px]/[22px] mb-1">
                                    <span class="text-gray-text font-medium">Полет:</span>
                                    <span class="font-bold">{{ item.flight_number }}</span>
                                </div>
                                <div class="font-semibold">
                                    {{ item.tariff }}
                                </div>
                            </div>
                            <div class="text-[20px]/[24px] font-semibold" v-if="item.currency?.symbol && item.price">
                                от {{ item.currency.symbol }}{{ item.price }}
                            </div>
                        </div>
                    </div>
                </Slide>
            </Carousel>
        </div>

    </div>
</template>

<script setup>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/carousel.css'

const config = {
    mouseDrag: true,
    pauseAutoplayOnHover: true,
    wrapAround: true,
    itemsToShow: 1.2,
    snapAlign: 'start',
    breakpointMode: 'viewport',
    gap: 20,
    // sm:basis-4/7  md:basis-2/5 xl:basis-2/7
    breakpoints: {
        640: {
            itemsToShow: 1.7,
        },
        768: {
            itemsToShow: 2.5,
        },
        1024: {
            itemsToShow: 3.5,
        },
    },
}
const { t } = useI18n()
const car = ref()
const formatDate = useFormattedDate()
const props = defineProps({
    popular: {
        type: Array,
        required: true
    }
})
const flightsComputed = computed(() => ({
}))
function durationText(str) {
    const els = str.split(':')
    return Object.entries({
        hour: parseInt(els[0]),
        min: parseInt(els[1]),
    }).filter(([key, value]) => key !== 'hour' || value > 0).map(([key, value]) => value + ' ' + t(key)).join(' ')
}
</script>