<template>
    <div class="py-[100px]">
        <div class="my-container">
            <h2 class="text-[24px]/[30px] md:text-[32px]/[40px] xl:text-[42px]/[53px] text-center mb-2 md:mb-3">
                {{ t('news') }} </h2>
            <div
                class="text-gray-text text-center text-[14px]/[17px] md:text-[16px]/[19px] xl:text-[20px]/[24px] mb-[40px]">
                {{ t('beAwareOfNews') }}</div>
        </div>
        <div class="my-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  mb-[40px] lg:px-[50px]">
            <div class="aspect-[1/1] bg-green rounded-2xl flex items-end w-full" v-for="el in news.slice(0, 3)"
                :style="`background: url(${el.image}) no-repeat center center/cover`">

                <NuxtLink class="rounded-2xl p-6 text-white backdrop-blur-lg	w-full f-basis"
                    :to="localePath('/news/' + el.slug)">
                    <div class="text-[24px]/[32px] mb-2">
                        {{ el.title }}
                    </div>
                    <div class="text-[14px]/[21px]">
                        {{ el.date }}
                    </div>
                </NuxtLink>
            </div>
        </div>
        <div class="flex justify-center">
            <NuxtLink :to="localePath('/news')"
                class="py-4 px-6 bg-my-green mx-auto block rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green">
                {{ t('allNews') }}
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n()
const localePath = useLocalePath()
const props = defineProps({
    news: {
        type: Array
    }
})
</script>