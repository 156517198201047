<template>
    <div>
        <HomeHero />
        <div class="py-[50px] bg-gray-bg-50">
            <HomeTablo class="mb-[50px]" />
            <HomeService :services />
        </div>
        <div class=" pt-[100px] pb-[80px]">
            <HomeFlight :flights />
        </div>
        <HomePopular :popular />
        <HomeNews :news />
    </div>
</template>

<script setup>
const rc = useRuntimeConfig();
const { locale, t } = useI18n()
const headers = computed(() => ({
    lang: locale.value
}))
const res = await Promise.all([
    $fetch(rc.public.api + '/api/home-services', {
        headers: headers.value
    }),
    $fetch(rc.public.api + '/api/fly-with-us', {
        headers: headers.value
    }),
    $fetch(rc.public.api + '/api/popular-flights', {
        headers: headers.value
    }),
    $fetch(rc.public.api + '/api/news', {
        headers: headers.value
    }),
])
const services = ref(res[0].data.services)
const flights = ref(res[1].data.flights)
const popular = ref(res[2].data.flights)
const news = ref(res[3].data.news)
</script>